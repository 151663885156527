import { Component, OnInit } from "@angular/core";
@Component({
  selector: "app-query-tab",
  templateUrl: "./query-tab.component.html",
  styleUrls: ["./query-tab.component.css"],
})
export class QueryTabComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
